import axios from "axios";
import { debug } from "@/constants";
import { getToken } from "@/token";

const baseURL: string = process.env.VUE_APP_API_ENDPOINT;
const instance = axios.create({
  baseURL,
});

export const setAuthorization = () => {
  const token = getToken();
  if (token) {
    instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
};

setAuthorization();

// logger
if (debug) {
  instance.interceptors.request.use((request) => {
    console.log(
      `%c${request.method?.toUpperCase()}: ${request.url}`,
      "font-weight: bold"
    );
    return request;
  });
}

export default instance;
