import { reactive, toRefs } from "vue";
import useApi, { Status } from "./useApi";
import { Config } from "@/types/config";

const state = reactive<Config>({
  site_name: "",
  googlerecaptcha_site_key: "",
  resend_verify_interval: 0,
  account_limitation: {
    content: "",
    title: "",
    video: "",
  },
  bankCardList: {},
  bankColorList: {},
  bank_logo: {},
  en_bankCardList: {},
  chat_max_file_size: 0,
  auto_settle_video: "",
  verification_video: "",
});

export default () => {
  const { call, response, status } = useApi<Config>();

  const fetch = async () => {
    await call({
      url: "/auth/config",
    });

    if (status.value === Status.SUCCESS && response.value) {
      const { status, success, ...rest } = response.value;
      Object.entries(rest).forEach(([k, v]) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        state[k] = v;
      });
    }
  };

  return {
    ...toRefs(state),
    fetch,
  };
};
