import { reactive, toRefs } from "vue";
import { useRouter } from "vue-router";
import useApi, { Status } from "./useApi";
import {
  LoginResponse,
  LoginForm,
  VerifyResponse,
  ResendResponse,
} from "@/types/auth";
import { ErrorType } from "@/types/base";
import { UserDetailsResponse } from "@/types/auth";
import { setToken, setUUID, removeToken, removeUUID } from "@/token";
import { setAuthorization } from "@/plugins/axios";

interface StateType {
  uuid: string;
  errors: {
    [key: string]: string[];
  };
  user: UserDetailsResponse | null;
}

const state = reactive<StateType>({
  uuid: "",
  errors: {},
  user: null,
});

export default () => {
  const router = useRouter();

  const {
    call: loginCall,
    response: loginResponse,
    status: loginStatus,
    error: loginError,
  } = useApi<LoginResponse>();
  const {
    call: verifyCall,
    response: verifyResponse,
    status: verifyStatus,
    error: verifyError,
  } = useApi<VerifyResponse>();
  const {
    call: resendCall,
    response: resnedResponse,
    status: resendStatus,
    error: resendError,
  } = useApi<ResendResponse>();
  const {
    call: userDetailsCall,
    response: userDetailsResponse,
    status: userDetailsStatus,
    error: userDetailsError,
  } = useApi<UserDetailsResponse>();

  const { call: logoutCall } = useApi();

  const login = async (data: LoginForm) => {
    clearError();

    await loginCall({
      url: "/auth/login",
      method: "POST",
      data,
    });

    if (loginStatus.value === Status.SUCCESS) {
      if (loginResponse.value) {
        setUUID(loginResponse.value.uuid);
      }
      void router.push({ name: "Verify" });
    }

    if (loginStatus.value === Status.SUCCESS && loginResponse.value) {
      state.uuid = loginResponse.value?.uuid;
    }

    if (loginStatus.value === Status.ERROR) {
      const { errors } = loginError.value as any;
      state.errors = errors;
    }
  };

  const verify = async (code: string) => {
    clearError();

    await verifyCall({
      url: "/auth/verify",
      method: "POST",
      data: {
        code,
        uuid: state.uuid,
      },
    });

    if (verifyStatus.value === Status.SUCCESS) {
      if (verifyResponse.value) {
        setToken(verifyResponse.value.Token);
        setAuthorization();

        await fetchUserDetails();

        userDetailsStatus.value === Status.SUCCESS &&
          void router.push({ name: "Profile" });
      }
    }

    if (verifyStatus.value === Status.ERROR) {
      const { errors } = verifyError.value as any;
      state.errors = errors;
    }
  };

  const fetchUserDetails = async () => {
    await userDetailsCall({
      url: "/user/details",
      method: "GET",
    });

    if (
      userDetailsStatus.value === Status.SUCCESS &&
      userDetailsResponse.value
    ) {
      const { status, success, ...rest } = userDetailsResponse.value;
      state.user = rest;
    }

    if (userDetailsStatus.value === Status.ERROR) {
      removeToken();
      removeUUID();
    }
  };

  const logout = async () => {
    removeToken();
    removeUUID();

    void router.push({ name: "Home" });

    await logoutCall({
      url: "/user/logout",
      method: "POST",
    });

    state.user = null;
  };

  const resend = async () => {
    await resendCall({
      url: "/auth/resend",
      method: "POST",
      data: {
        uuid: state.uuid,
      },
    });

    if (resendStatus.value === Status.ERROR) {
      const { errors } = resendError.value as any;
      state.errors = errors;
    }
  };

  const setError = (error: ErrorType) => {
    state.errors = error.errors;
  };

  const clearError = (key?: string) => {
    if (key) {
      delete state.errors[key];
    } else {
      state.errors = {};
    }
  };

  return {
    login,
    loginResponse,
    loginStatus,
    verify,
    verifyResponse,
    verifyStatus,
    verifyError,
    setError,
    clearError,
    resend,
    resnedResponse,
    resendStatus,
    fetchUserDetails,
    userDetailsResponse,
    userDetailsStatus,
    userDetailsError,
    logout,
    ...toRefs(state),
  };
};
