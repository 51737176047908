export const debug = process.env.NODE_ENV !== "production";
export const VariantTypes = ["primary", "danger", "success"];
export const SOCIAL = {
  TELEGRAM: "https://t.me/walletex",
  TWITTER: "https://twitter.com/walletexchange",
  INSTAGRAM: "https://instagram.com/reypayment",
  LINKEDIN: "https://www.linkedin.com/company/reypay/?originalSubdomain=ir",
  YOUTUBE: "https://www.youtube.com/channel/UC3lSE_OCIxoKeg0dh-RQlAA",
  APARAT: "https://www.aparat.com/ReyPayment",
};
