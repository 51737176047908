enum Auth {
  Token = "Token",
  UUID = "uuid",
}

export const setToken = (token: string) => {
  localStorage.setItem(Auth.Token, token);
};
export const setUUID = (uuid: string) => {
  localStorage.setItem(Auth.UUID, uuid);
};

export const getToken = () => localStorage.getItem(Auth.Token);
export const getUUID = () => localStorage.getItem(Auth.UUID);

export const removeToken = () => localStorage.removeItem(Auth.Token);
export const removeUUID = () => localStorage.removeItem(Auth.UUID);
