<template>
  <router-view />
</template>

<script>
import { defineComponent } from "vue";
import useConfig from "@/composables/useConfig";
import useAuth from "@/composables/useAuth";
import { getToken } from "@/token";

export default defineComponent({
  name: "App",
  setup() {
    const { fetch } = useConfig();
    const { fetchUserDetails } = useAuth();

    fetch();
    getToken() && fetchUserDetails();
  },
});
</script>
