import { reactive, toRefs } from "vue";
import { AxiosRequestConfig, AxiosError } from "axios";
import axios from "@/plugins/axios";
import { ResponseType } from "@/types/base";
import { removeToken, removeUUID } from "@/token";

export enum Status {
  IDLE = "idle",
  LOADING = "loading",
  SUCCESS = "success",
  ERROR = "error",
}

type Options = Pick<
  AxiosRequestConfig,
  | "url"
  | "method"
  | "data"
  | "params"
  | "paramsSerializer"
  | "onUploadProgress"
  | "cancelToken"
>;

interface Result<K> {
  status: Status;
  response: null | K;
  success: boolean;
  error: null;
}

export default function useApi<T>() {
  const state = reactive<Result<T & ResponseType>>({
    status: Status.IDLE,
    response: null,
    success: true,
    error: null,
  });

  const call = async ({
    data,
    method,
    params,
    url,
    paramsSerializer,
    onUploadProgress,
    cancelToken,
  }: Options) => {
    state.status = Status.LOADING;

    try {
      const response = await axios.request({
        method,
        url,
        data,
        params,
        paramsSerializer,
        onUploadProgress,
        cancelToken,
      });

      state.response = response.data;
      state.status = Status.SUCCESS;
      state.success = true;
    } catch (error) {
      const { response } = error as AxiosError;

      // on 401 error logout
      if (response?.status === 401) {
        console.log("401 error");
        removeToken();
        removeUUID();
        axios.defaults.headers.common["Authorization"] = "";

        const exceptions = ["buy/invoice", "sell/invoice"];

        if (
          !exceptions.some((exception) =>
            window.location.pathname.includes(exception)
          )
        ) {
          window.location.href = "/auth";
        }
      }

      state.status = Status.ERROR;
      state.success = false;
      state.error = response?.data;
    }
  };

  return {
    ...toRefs(state),
    call,
  };
}
