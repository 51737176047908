import { createRouter, createWebHistory } from "vue-router";
import { breakpointsBootstrapV5 } from "@vueuse/core";
import routes from "./routes";
import { getToken, getUUID } from "@/token";

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  if (
    to.path.includes("/account") &&
    (!getToken() || !getUUID()) &&
    to.name !== "SellInvoice" &&
    to.name !== "BuyInvoice"
  ) {
    next({ name: "Auth" });
  } else if (to.path.includes("/auth") && getToken() && getUUID()) {
    next({ name: "Profile" });
  } else if (
    document.body.clientWidth > breakpointsBootstrapV5.lg &&
    to.path === "/account/profile"
  ) {
    next({ name: "Home" });
  } else {
    next();
  }
});

export default router;
